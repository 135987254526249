body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2 {
  font-weight: 600
}

h3 {
  font-weight: 400;
}

h1 {
  font-size: 15px;
  line-height: 10.8px;
  color: #D3B033;
}

h2, h3 {
  font-size: 10px;
  line-height: 12px;
}

@media (min-width: 576px) {
  h1 {
    font-size: 22px;
    line-height: 15.84px;
  }
  
  h2, h3 {
    font-size: 17px;
    line-height: 24px;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 25px;
    line-height: 18px;
  }
  
  h2, h3 {
    font-size: 20px;
    line-height: 27px;
  }
}

h2 a {
	color: #000;
	text-decoration: none;
}
