.styles_backgroundRestaurant__30U3R,
.styles_backgroundWhite__2uEBD {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.styles_backgroundRestaurant__30U3R {
    background-image: url(/static/media/restaurant.b2aa153b.jpg);
}

.styles_backgroundWhite__2uEBD {
    background-image: url(/static/media/white.4b2ea686.svg);
}

.styles_logo__34CCJ {
    width: 47.13px;
    height: 60.27px;
}

.styles_logoTitleSpace__2gCWK {
    height: 12.06px;
}

.styles_titleDescriptionSpace__z6Jp6 {
    height: 16.38px;
}

@media (max-width: 575px) {
  img {
  	width: 200px !important;
    height: 200px !important;
    margin-top: -60px !important;
  }
  
  h1 {
  	font-size: 27px !important;
  }
  
  h2, h3 {
  	font-size: 17px !important;
  	line-height: 25px !important;
  }
}

@media (min-width: 576px) {
    .styles_logo__34CCJ {
        width: 94.95px;
        height: 121.41px;
    }
    
    .styles_logoTitleSpace__2gCWK {
        height: 26px;
    }
    
    .styles_titleDescriptionSpace__z6Jp6 {
        height: 51.08px;
    }
}

@media (min-width: 992px) {
    .styles_logo__34CCJ {
        width: 108.71px;
        height: 139px;
    }
    .styles_logoTitleSpace__2gCWK {
        height: 32.25px;
    }
    
    .styles_titleDescriptionSpace__z6Jp6 {
        height: 52.81px;
    }
}

.styles_downloadSection__sNkJm {
	margin-top: 20px;
	text-align: center;
}

.styles_downloadButton__2LqSw {
	background-color: #D3B033;
    color: #000;
    width: 200px;
    height: 40px;
    border-radius: 30px;
    outline: none;
    border: none;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 20px;
}
  
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2 {
  font-weight: 600
}

h3 {
  font-weight: 400;
}

h1 {
  font-size: 15px;
  line-height: 10.8px;
  color: #D3B033;
}

h2, h3 {
  font-size: 10px;
  line-height: 12px;
}

@media (min-width: 576px) {
  h1 {
    font-size: 22px;
    line-height: 15.84px;
  }
  
  h2, h3 {
    font-size: 17px;
    line-height: 24px;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 25px;
    line-height: 18px;
  }
  
  h2, h3 {
    font-size: 20px;
    line-height: 27px;
  }
}

h2 a {
	color: #000;
	text-decoration: none;
}

