.backgroundRestaurant,
.backgroundWhite {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.backgroundRestaurant {
    background-image: url(./restaurant.jpg);
}

.backgroundWhite {
    background-image: url(./white.svg);
}

.logo {
    width: 47.13px;
    height: 60.27px;
}

.logoTitleSpace {
    height: 12.06px;
}

.titleDescriptionSpace {
    height: 16.38px;
}

@media (max-width: 575px) {
  img {
  	width: 200px !important;
    height: 200px !important;
    margin-top: -60px !important;
  }
  
  h1 {
  	font-size: 27px !important;
  }
  
  h2, h3 {
  	font-size: 17px !important;
  	line-height: 25px !important;
  }
}

@media (min-width: 576px) {
    .logo {
        width: 94.95px;
        height: 121.41px;
    }
    
    .logoTitleSpace {
        height: 26px;
    }
    
    .titleDescriptionSpace {
        height: 51.08px;
    }
}

@media (min-width: 992px) {
    .logo {
        width: 108.71px;
        height: 139px;
    }
    .logoTitleSpace {
        height: 32.25px;
    }
    
    .titleDescriptionSpace {
        height: 52.81px;
    }
}

.downloadSection {
	margin-top: 20px;
	text-align: center;
}

.downloadButton {
	background-color: #D3B033;
    color: #000;
    width: 200px;
    height: 40px;
    border-radius: 30px;
    outline: none;
    border: none;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 20px;
}
  